
















import Vue from 'vue'
import { ItemCardComponent, ItemCard } from '../components'
import { ConfigContato, Galeria } from '@/entities'
import { routerNames } from '@/router'
import { Route } from 'vue-router'
import { LightBox } from '../components/lightbox'
import { filter } from 'vue/types/umd'

export default Vue.extend({
  name: 'portfolio',
  components: {
    ItemCardComponent,
    LightBox,
  },
  data() {
    return {
      configContato: {} as ConfigContato,
      configGaleria: {} as Galeria[],
      itensCard: [] as ItemCard[],
      itemSelected: '',
      showItem: false,
      sizePage: {
        width: 0,
        height: 0
      }   
    }
  },
  created() {
    this.configGaleria = this.dataConfig.galeria
    console.log(this.configGaleria)
    this.configContato = this.dataConfig.contato
    this.itensCard = [
      // { name: 'jr-educacional', srcImg: 'arquivos/imagens/logo-jr-educacional.png', title: 'JR Educacional', subTitile: 'Gestão Acadêmica', route: routerNames.portfolio },
      { name: 'jr-appeduc', srcImg: 'arquivos/imagens/logo-appeduc.png', title: 'JR AppEduc', subTitile: 'Aplicativo Acadêmico', route: routerNames.portfolio },
      { name: 'jr-atend', srcImg: 'arquivos/imagens/log-jratend.png', title: 'JR Atend', subTitile: 'Gestão de Atendimento', route: routerNames.portfolio },
      { name: 'jr-powerp', srcImg: 'arquivos/imagens/logo-powerp.png', title: 'JR PowerP', subTitile: 'Conectando processo e pessoas', route: routerNames.portfolio },
      { name: 'my-sign', srcImg: 'arquivos/imagens/logo-mysigin.png', title: 'My Sign', subTitile: 'Assinatura eletrônica', route: routerNames.portfolio },
      { name: 'nfse', srcImg: 'arquivos/imagens/logo-nfse.png', title: 'NFS-e', subTitile: 'Emissor de Nota Fiscal', route: routerNames.portfolio },
    ]
  },
  mounted() {
    const body = document.body
    this.sizePage = {
      width: body.offsetWidth,
      height: body.offsetHeight
    }
  },
  watch: {
     '$route.params.item': {
        handler(item: string) {
          this.$route.meta.title = 'Portfólio'
          if(item) {                    
            this.showItem = true
            this.itemSelected = item     
          } else {
            this.showItem = false
            this.itemSelected = ""  
          }
        },
        deep: true,
        immediate: true,
    },
  },
  methods: {  
    onClickPortfolioItem(item: ItemCard) {    
      this.$route.meta.title = item?.title ? item?.title : this.$route.meta.title    
      this.$router.push({ name: routerNames.portfolio, params: { item: item.name} })
    },
    getItemCard(name: string) {
      return this.itensCard.find(item => item.name === name)
    }
  },
  computed: {
    getItemGalery(): Galeria[] {
      if(!this.itemSelected)
        return []

      return this.configGaleria.filter(galeria => galeria.category == this.itemSelected)
    },
    getHeight(): number {                  
      if(this.sizePage.width <= 690)
        return this.sizePage.height - 297

      return this.sizePage.height - 340          
    },
    getWidth(): number {                        
      return this.sizePage.width
    },
  }
})
