



















import Vue, { PropOptions } from 'vue'
import { ItemCard } from './item-card-entities'

export default Vue.extend({
  name: 'list-item-card-component',
  props: {
    item: <PropOptions<ItemCard>>{
      type: Object,
      required: true,
    },
  },
  methods: {
    onClicItem() {
      this.$emit('click-item', Object.assign({}, this.item ))
    }
  }
})
