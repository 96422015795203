import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { CartaoVirtualTamplate } from '../components/template'
import { routerNames } from './router-names'
import { CartaoVirtual, GaleriaPage, PortfolioPage } from '../pages'
import { Config } from '@/entities'

Vue.use(VueRouter)

const initRoutes = (config: Config): VueRouter => {
  const routes = [
    {
      path: '/',
      name: routerNames.template,
      component: CartaoVirtualTamplate,
      redirect: routerNames.cartaoVirtual,
      children: [
        {
          path: '/',
          name: routerNames.cartaoVirtual,
          component: CartaoVirtual
        },
        {
          path: '/portfolio/:item?',
          name: routerNames.portfolio,
          meta: { title: 'Portfólio' },
          component: PortfolioPage
        },
      ]
    },
    // {
    // 	path: '/redirect/:path*',
    // 	name: routerNames.redirect,		
    // 	component: RedirectPage,
    // }, 
  ]


  const router = new VueRouter({
    routes,
    mode: 'hash'
  })
  return router
}

export { initRoutes }
