








































import Vue from 'vue'
import { Config } from '@/entities'
import { routerNames } from '@/router'
import { Route } from 'vue-router'

export default Vue.extend({
  name: 'cartao-virtual-tamplate',
  data() {
    return {
      config: {} as Config,
      isBtnPreviousPage: false,
      textPreviousPage: ''
    }
  },
  created() {
    this.config = this.dataConfig
  },
  watch: {
    '$route': {
      handler(route: Route) {
        this.isBtnPreviousPage = route.name !== routerNames.cartaoVirtual   
        this.textPreviousPage = route.meta?.title ? route.meta?.title : ''
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onPreviousPage() {
      window.history.back()
    }
  }
})
