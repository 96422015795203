import Vue from 'vue'
import App from './App.vue'
import VModal from "vue-js-modal";
import { initRoutes } from './router'
import axios from 'axios';
import { Config } from './entities';
const { version } = require("../package.json");


Vue.use(VModal);

Vue.config.productionTip = false
window.title = "JR Sistemas"


const main = async () => {

  await getConfig();

  new Vue({
    router: initRoutes(Vue.prototype.dataConfig),
    render: h => h(App),
  }).$mount('#app')

}


const getConfig = async () => {  
  try {
    console.log(`versão: ${version}`)
    const response = await axios.get(`arquivos/config.json?v=${version}`);
    const dataConfig = response.data as Config;
    
    Vue.prototype.dataConfig = dataConfig
  } catch (e) {
    console.log("error get config", e);
  }
}

main()
