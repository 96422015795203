













































































































































































































































import { ConfigContato } from '@/entities'
import { routerNames } from '@/router'
import Vue from 'vue'
import VModal from 'vue-js-modal'
Vue.use(VModal)

export default Vue.extend({
  name: 'ConteudoCard',
  data() {
    return {
      configContato: {} as ConfigContato,
    }
  },
  created() {
    this.configContato = this.dataConfig.contato
  },
  methods: {
    showModalContact() {
      this.$modal.show('modal-save-contact')
    },
    hideModalContact() {
      this.$modal.hide('modal-save-contact')
    },
    showModalShared() {
      this.$modal.show('modal-save-shared')
    },
    hideModalShared() {
      this.$modal.hide('modal-save-shared')
    },
    onClickGalery() {
      this.$router.push({ name: routerNames.portfolio })
    },
  },
})
