




import { Config } from '@/entities'
import Vue, { PropOptions } from 'vue'
import VModal from 'vue-js-modal'
import { LightBox } from '../components/lightbox'

Vue.use(VModal)

export default Vue.extend({
  name: 'Galeria',
  components: {
    LightBox,
  },
  data() {
    return {
      config: {} as Config,   
      sizePage: {
        width: 0,
        height: 0
      }   
    }
  },
  created() {
    this.config = this.dataConfig
  },
  mounted() {
    const body = document.body
    this.sizePage = {
      width: body.offsetWidth,
      height: body.offsetHeight
    }
  },
  computed: {
    getHeight(): number {                  
      if(this.sizePage.width <= 690)
        return this.sizePage.height - 297

      return this.sizePage.height - 340          
    },
    getWidth(): number {                        
      return this.sizePage.width
    },
  }
})
