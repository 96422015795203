
















































import Vue, { PropOptions, VueConstructor } from 'vue'

import VueItBigger from 'vue-it-bigger'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'

import { Galeria } from '@/entities'

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        VueItBigger: HTMLFormElement
      }
    }
  >
).extend({
  components: {
    VueItBigger,
  },
  props: {
    items: <PropOptions<Galeria[]>>{
      type: Array,
      default: () => {
        return [] as Galeria[]
      },
    },
    css: {
      type: String,
      default: () => 'h-250 h-md-400 h-lg-600',
    },
    height: {
      type: Number,
      default: () => 450,
    },
    width: {
      type: Number,
      default: () => 690,
    },
    cells: {
      type: Number,
      default: () => 5,
    },
  },
  data() {
    return {
      src: '',
      index: -1,
      loading: false,
      events: [] as any[],
    }
  },
  methods: {
    openGallery(index: number) {
      this.$refs.VueItBigger.showImage(index)
    },
    bg(i: any) {
      if (!i || i.length === 0) return ''
      return `background-image: url('${i}')`
    },
    isVideo(item: Galeria) {
      return item.type == 'image' ? false : true
    },
  },
  computed: {
    getStyle(): string {
      return `height: ${this.height}px`
    },
  },
})
